import * as React from 'react';
import { BaSeI18nConsumer } from '../../../contexts/i18n';
import { useCoordinatesFromBuddyAndDirectionToAlert } from '../../../hooks/coordinates-from-buddy-and-direction';
import { useDebounce } from '../../../hooks/debounce';
import { ThemeNamedColor } from '../../../theme/theme-interface';
import { transformLightness } from '../../../utils/color-transformation/transform-lightness';
import { BaSeIconButton } from '../../button/shape-button/icon-button';
import { BaSeSmall1 } from '../../typography/small/small1';
import { AlertProps } from '../alerts-props';
import {
  getTriangleMapper,
  getTriangleMapperSmaller,
  PopoverColorMapper,
} from './popover-map';
import {
  PopoverButtonContainer,
  PopoverCard,
  PopoverCardBodyContainer,
  PopoverCardContentContainer,
  PopoverCardTitleContainer,
  PopoverContainer,
  PopoverTriangle,
  PopoverTriangleSmaller,
} from './popover-styled';

export interface PopoverProps
  extends Omit<AlertProps, 'message' | 'messageNoBreakLine' | 'size'> {
  message?: string;
  hasCloseButton?: boolean;
  renderContent?: () => JSX.Element;
}

export type EmbedPopoverProps = Partial<
  Omit<PopoverProps, 'open' | 'buddyRef' | 'refOutput' | 'hasCloseButton'>
>;

export const BaSePopover: React.FC<PopoverProps> = ({
  width,
  title = '',
  message = '',
  direction = 'neutral',
  color = 'default',
  themeMode = 'light',
  coordinates = {},
  delayToOpen = 0,
  overrideColors,
  buddyRef,
  hasCloseButton = true,
  open: externalOpen = false,
  handleClose = () => {},
  refOutput = () => {},
  renderContent = () => null,
}) => {
  const open = useDebounce(externalOpen, externalOpen ? delayToOpen : 0);

  const mappedColor = PopoverColorMapper[color as ThemeNamedColor] ?? color;

  const TriangleProps = React.useCallback(
    () =>
      getTriangleMapper(
        themeMode,
        mappedColor,
        direction,
        overrideColors?.borderColor,
      ),
    [themeMode, mappedColor, direction, overrideColors],
  );

  const TrianglePropsSmaller = React.useCallback(
    () =>
      getTriangleMapperSmaller(
        themeMode,
        mappedColor,
        direction,
        overrideColors?.backgroundColor,
      ),
    [themeMode, mappedColor, direction, overrideColors],
  );

  const { top, left, bottom, right } =
    useCoordinatesFromBuddyAndDirectionToAlert(
      buddyRef,
      direction,
      coordinates,
    );

  const ref = React.useRef(null);

  React.useEffect(() => {
    if (ref?.current) {
      refOutput(ref?.current);
    }
  }, [ref, message, refOutput]);

  return (
    <PopoverContainer
      aria-hidden={!open}
      open={open}
      ref={ref}
      top={top}
      left={left}
      bottom={bottom}
      right={right}
      width={width}
    >
      <PopoverTriangle {...TriangleProps()} />
      <PopoverTriangleSmaller {...TrianglePropsSmaller()} />
      <PopoverCard
        color={mappedColor}
        themeMode={themeMode}
        overrideColors={overrideColors}
      >
        <PopoverCardTitleContainer
          color={mappedColor}
          themeMode={themeMode}
          overrideColors={overrideColors}
        >
          <BaSeSmall1>{title}</BaSeSmall1>
          {hasCloseButton && (
            <PopoverButtonContainer>
              <BaSeI18nConsumer>
                {({ getMessage }) => (
                  <BaSeIconButton
                    nameIcon="close-big"
                    descriptionIcon={getMessage(
                      'buttonIcon.iconDescription.close',
                    )}
                    buttonType="button"
                    shape="circle"
                    size="small"
                    sizeIcon="medium"
                    type="tertiary"
                    color={
                      overrideColors?.titleColor ||
                      (themeMode === 'light'
                        ? color
                        : transformLightness(mappedColor, 0.9))
                    }
                    onClick={handleClose}
                  />
                )}
              </BaSeI18nConsumer>
            </PopoverButtonContainer>
          )}
        </PopoverCardTitleContainer>
        <PopoverCardBodyContainer
          overrideColors={overrideColors}
          themeMode={themeMode}
        >
          <BaSeSmall1>{message}</BaSeSmall1>
          <PopoverCardContentContainer>
            {renderContent()}
          </PopoverCardContentContainer>
        </PopoverCardBodyContainer>
      </PopoverCard>
    </PopoverContainer>
  );
};

BaSePopover.displayName = 'BaSePopover';
